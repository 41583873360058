<template>
	<v-app>
		<v-card>
			<template v-if="placeId">
				<v-card-title>地点の編集</v-card-title>
			</template>
			<template v-else>
				<v-card-title>新規地点</v-card-title>
			</template>
			<v-btn depressed @click="remove" class="remove">削除</v-btn>
			<v-card-text>
				<template v-if="placeId">
					<v-label> ID </v-label>
					<v-text-field v-model="place.id" outlined disabled></v-text-field>
				</template>

				<v-label>名称</v-label>
				<v-text-field :rules="[rules.required, rules.max]" counter="20" outlined v-model="place.name"> </v-text-field>

				<v-label>住所</v-label>
				<v-text-field :rules="[rules.required, rules.max2]" counter="100" outlined v-model="place.address"> </v-text-field>

				<v-label>緯度</v-label>
				<v-text-field :rules="[rules.required]" outlined v-model="place.latitude"> </v-text-field>

				<v-label>経度</v-label>
				<v-text-field :rules="[rules.required]" outlined v-model="place.longitude"> </v-text-field>

				<template v-if="placeId">
					<v-label>カメラ一覧</v-label>
					<v-select
						disabled
						v-model="place.cameras"
						:items="cameras"
						multiple
						chips
						outlined
						persistent-hint
					></v-select>
				</template>
			</v-card-text>
			<template v-if="placeId">
				<v-card-actions>
					<v-btn depressed @click="save" class="save">保存</v-btn>
				</v-card-actions>
			</template>
			<template v-else>
				<v-card-actions>
					<v-btn depressed @click="save" class="save">地点を追加</v-btn>
				</v-card-actions>
			</template>
		</v-card>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="headline">
					本当にこの地点を削除しますか？
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete"
						>キャンセル</v-btn
					>
					<v-btn color="blue darken-1" text @click="deleteConfirm">OK</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data: () => ({
		placeId: "",
		place: {},
		cameras: [],
		dialogDelete: false,
		rules: {
			required: v => !!v || '必須項目',
			max: v => v.length <= 20 || "20文字以内",
			max2: v => v.length <= 100 || "100文字以内",
		},
	}),
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.initialize();
	},
	mounted() {
		this.placeId = this.$route.params.id;
	},
	methods: {
		initialize() {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-place?id=" +
						this.$route.params.id
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.place = res.data.place;
						this.cameras = res.data.cameras;
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		save() {
			if (!this.place.name || this.place.name.length > 20) {
				alert("地点名は20文字以内で指定してください");
				return;
			}
			if (!this.place.address || this.place.address.length > 100) {
				alert("地点住所は100文字以内で指定してください");
				return;
			}
			this.showLoading();
			const params = {
				name: this.place.name,
				address: this.place.address,
				latitude: this.place.latitude,
				longitude: this.place.longitude,
			};
			if (this.placeId) params.id = this.placeId;
			axios
				.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-place",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.$router.push({ name: "AdminPlace" }).catch(() => {});
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("名称、住所、緯度、経度は必須項目です");
				});
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.showLoading();
			const params = {
				id: this.placeId,
				delete: "true",
			};
			axios
				.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-place",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.$router.push({ name: "AdminPlace" }).catch(() => {});
					} else {
						console.log("Fail: " + JSON.stringify(res));
						alert("不明なエラー");
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("不明なエラー");
				});
		},
		remove() {
			this.dialogDelete = true;
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
};
</script>

